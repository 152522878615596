<template>
    <div class="h-full" v-if="hackReset">
        <div :class="config.isDetailDisplay ? 'small-list' : 'scroll-auto'">
            <Control ref="control" :config="config" @onChangeEditDataSource="onChangeEditDataSource"></Control>
        </div>
        <div v-show="config.isDetailDisplay" class="detail">
            <EditControl :dataSource="editDataSource" :config="config"></EditControl>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                config: {
                    isDetailDisplay: false,
                },
                editDataSource: {},
                initialDataSource: {},
                hackReset: true,
            }
        },
        methods: {
            init: function (obj) {
                this.config = {
                    isDetailDisplay: false,
                };
                this.editDataSource = {};
                this.initialDataSource = {};

            },
            onChangeEditDataSource: function (dataSource) {
                this.editDataSource = dataSource;
                this.initialDataSource = this.Utils.extend({}, dataSource);
            },
            syncDataSource() {
                this.initialDataSource = this.Utils.extend({}, this.editDataSource);
            },
            checkSaveStatus() {
                this.config.isDetailDisplay = false;
            }
        },
        components: {
            "EditControl": resolve => { require(['./components/editcustomershop.vue'], resolve) },
            "Control": resolve => { require(['./components/customershop.vue'], resolve) },
        }
    }
</script>

<style>

</style>